@import "mixin";
@import "variables";

@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

:root {
	font-size: 62.5%;
	height: 100%;
	overflow: hidden;

	body {
		overflow: hidden;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		font-size: 1.6rem;
		margin: 0;
		font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

		code {
			font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
			monospace;
		}

		.h1 {
			font-weight: 500;
			font-size: 6.8rem;
			line-height: 7.2rem;
		}

		.h2 {
			font-weight: 500;
			font-size: 2.6rem;
			line-height: 3.2rem;
			letter-spacing: 0.02em;
		}

		.h3 {
			font-weight: 500;
			font-size: 2.6rem;
			line-height: 4.8rem;
		}

		.h4 {
			font-weight: 500;
			font-size: 2rem;
			line-height: 2.4rem;
		}

		.h5 {
			font-weight: 500;
			font-size: 1.8rem;
			line-height: 2.4rem;
		}

		.h6 {
			font-weight: 500;
			font-size: 1.6rem;
			line-height: 2.4rem;
		}

		.b1 {
			font-size: 1.8rem;
			line-height: 2.4rem;
		}

		.b2 {
			font-size: 1.6rem;
			line-height: 2.4rem;
		}

		.b3 {
			font-size: 1.4rem;
			line-height: 2.4rem;
		}

		.b4 {
			font-size: 1.2rem;
			line-height: 1.6rem;
		}

		.b5 {
			font-size: 1rem;
			line-height: 1.6rem;
		}
	}

	#root {
		flex: 1 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		width: 100%;
	}

	.i-flex {
		display: inline-flex;
	}

	.accordion {

		&__item {
			border-bottom: .2rem solid $color-10;
			margin-bottom: .4rem;
		}

		&__button {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1.2rem .3rem;
			font-weight: 500;
			font-size: 1.6rem;
			line-height: 2.4rem;
			letter-spacing: 0.03em;
			width: 100%;
			color: $color-5;
			cursor: pointer;
			border: none;
			outline: none;
			transition: color .25s ease-in-out;

			&:hover {
				color: transparentize($color-5, 0.4);
			}

			svg {
				transition: transform .25s ease-in-out;
			}

			&[aria-expanded='true'] svg,
			&[aria-selected='true'] svg {
				transform: rotate(180deg);
			}
		}

		&__panel {
			padding: 0 .3rem 1.2rem;
			animation: fadein .25s ease-in-out;
			font-size: 1.6rem;
			line-height: 2.4rem;
			letter-spacing: 0.02em;
			color: $color-5;
		}

		[hidden] {
			display: none;
		}
	}

	.Toastify__toast {

		//&--dark {}

		//&--default {}
		&--info {
			background-color: $color-4;
		}

		&--success {
			background-color: $color-8;
		}

		&--warning {
			background-color: $color-7;
		}

		&--error {
			background-color: $color-6;
		}
	}

	.modal {
		z-index: 300;
		/*position: absolute;
		top: 40px;
		left: 40px;
		right: 40px;
		bottom: 40px;*/
		width: -webkit-fill-available;
		background-color: transparent;
		outline: none;
	}

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: transparentize($color-5, 0.44);
		z-index: 200;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.react-datepicker {
		@include poppins-regular();
		border: none;
		border-radius: .3rem;
		background-color: $color-1;
		box-shadow: 0 .4rem .4rem transparentize($color-24, 0.92), 0 .4rem .8rem transparentize($color-24, 0.94);
		font-size: 1.4rem;

		&-popper {
			z-index: 10;
		}

		&-wrapper {
			width: 100%;
		}

		&__header {
			background-color: $color-1;
			border-bottom: none;
			padding: 1.6rem 1.6rem 1rem;

			&__dropdown {
				margin-bottom: 1rem;
			}
		}

		&__time {

			&-box {
				width: 100%!important;
				border-bottom-right-radius: .3rem;
				overflow: hidden;
			}

			&-container {
				border-color: $color-10;
			}

			&-list {

				&-item {
					display: flex;
					align-items: center;
					justify-content: center;

					&--selected {
						background-color: $color-10!important;
					}
				}
			}
		}

		&__navigation {
			outline: none;

			&--previous,
			&--next {
				top: 2.2rem;
			}

			&--previous {
				border-right-color: $color-5;
				left: auto;
				right: 3rem;
			}

			&--next {
				border-left-color: $color-5;
			}
		}

		&__year {

			&-option {
				font-size: 1.6rem;
				line-height: 2.4rem;

				&:hover {
					background-color: $color-9;
				}

				&--selected_year {
					background-color: $color-9;
					color: $color-4;
				}
			}

			&-dropdown {
				border: none;
				border-radius: .3rem;
				background-color: $color-1;
				box-shadow: 0 0 1rem transparentize($color-17, 0.85);
			}

			&-read {

				&-view {
					font-weight: 600;
					font-size: 1.6rem;
					line-height: 2.4rem;
					letter-spacing: 0.03em;
					text-transform: capitalize;

					&--selected-year {
						margin-right: .5rem;
					}

					&--down-arrow {
						border-top-color: $color-5;
					}
				}
			}
		}

		&__day {
			outline: none;
			width: 4.1rem;
			height: 3.2rem;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			color: $color-5;
			border: .2rem solid transparent;
			transition: opacity .25s ease-in-out,
				background-color .25s ease-in-out,
				color .25s ease-in-out,
				border-color .25s ease-in-out;

			&:hover {
				background-color: transparent;
				border-color: $color-4;
			}

			&-name {
				font-weight: 600;
				font-size: 1.2rem;
				line-height: 1.6rem;
				letter-spacing: 0.04em;
				text-transform: uppercase;
				color: $color-5;
				width: 4.1rem;
				height: 1.6rem;

				&s {
					display: flex;
					align-items: center;
				}
			}

			&--selected {
				border-radius: .3rem;
				color: $color-4;
				border-color: $color-4;
				background-color: transparent;

				&:hover {
					background-color: transparent;
				}
			}

			&--keyboard-selected {
				color: $color-4;
				border-color: $color-4;
				background: transparent;

				&:hover {
					background-color: transparent;
					color: $color-5;
					border-color: $color-5;
				}
			}

			&--outside-month {
				color: $color-11;
			}

			&--in {

				&-range {
					background-color: transparentize($color-4, .44);
					font-weight: bold;
					color: $color-1;
				}

				&-selecting-range {
					background-color: $color-4;

					&:hover {
						color: $color-4;
					}
				}
			}

			&--disabled {
				opacity: .5;
			}
		}

		&__current {

			&-month {
				font-weight: 600;
				font-size: 1.6rem;
				line-height: 2.4rem;
				letter-spacing: 0.03em;
				text-transform: capitalize;
				color: $color-5;
				text-align: left;
				margin-bottom: 1rem;
			}
		}
	}
}

@media (min-width: $desktop) {
	:root {

		body {

			.h2 {
				font-size: 4.2rem;
				line-height: 4.8rem;
				letter-spacing: initial;
			}
		}

		.accordion {

			&__item {
				margin-bottom: .8rem;
			}

			&__button {
				padding: 1.6rem .3rem;
			}

			&__panel {
				padding: 0 .3rem 1.6rem;
			}
		}
	}
}

@media print {

	html, body {
		height: initial !important;
		overflow: initial !important;
	}

}
