$color-1: #fff;
$color-2: #000;
$color-3: #303030;
$color-4: #3498DB;
$color-5: #1B2B4B;
$color-6: #FF4E44;
$color-7: #F1C40F;
$color-8: #41B738;
$color-9: #FAFAFA;
$color-10: #EFEFEF;
$color-11: #828282;
$color-12: #466694;
$color-13: #FF4238;
$color-14: #E5E5E5;
$color-15: #4786FF;
$color-16: #EFEFEF;
$color-17: #466694;
$color-18: #103F7F;
$color-19: #51C12C;
$color-20: #D6F6FD;
$color-21: #FEF8CE;
$color-22: #C0C0C0;
$color-23: #FCFCFC;
$color-24: #323247;
$color-25: #0B1837;
$color-26: #93152E;
$color-27: #7A0D2B;
$color-28: #8B6804;
$color-29: #735302;
$color-30: #116A21;
$color-31: #0A571E;
$color-32: #092C69;
$color-33: #07102C;
$color-34: #040B24;

$desktop: 1158px;
$pc-width: 1601px;
$m-padding: 1.5rem;
$d-padding: calc(50% - 56.4rem);
$f-padding: 3rem;