@mixin poppins-regular() {
	font-family: 'Poppins', sans-serif;
	font-style: normal;
	font-weight: normal;
}

@mixin absoluteCenter() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate( -50%, -50% );
}

@mixin absoluteCenterX() {
	position: absolute;
	left: 50%;
	transform: translateX( -50% );
}

@mixin absoluteCenterY() {
	position: absolute;
	top: 50%;
	transform: translateY( -50% );
}

@mixin text-hidden() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin hide-scrollbar() {
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}
}

@mixin flexSpaceBetween() {
	display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flexCenter() {
	display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flexBlock() {
	display: flex;
    flex-basis: auto;
    flex-direction: column;
}