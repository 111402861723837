@import "../../styles/variables";

.tooltip {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 14px;
	height: 14px;
	margin: 0 5px;
	background-color: transparentize($color-6, .6);
	color: $color-1;
	border-radius: 50%;
	cursor: pointer;
}